@tailwind base;
@tailwind components;
@tailwind utilities;

.rdw-editor-toolbar {
    border: none !important;
    border-bottom: 1px solid #ccc !important;
}
.rdw-dropdown-selectedtext{
    border: 1px solid #ccc;
    border-radius: 5px;
}
.rdw-remove-wrapper{
    height: 28px;
    border: 1px solid #ccc;
    border-radius: 5px;
}
.rdw-option-wrapper{
    height: 100%;
}